import { 
    defineComponent, 
    reactive,
    getCurrentInstance, 
    ComponentInternalInstance,
    onMounted 
 } from 'vue'
import { useRouter } from 'vue-router'
import { refundCreate, remaining } from '@/api'
import { SET_REFUND_RESULT } from '@/utils/constant'
import './index.less'

export default defineComponent({
    setup () {

        const router = useRouter()
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties

        const state: any = reactive({
            form: {
                bank: '',
                bankAccount: '',
                ownerName: '',
                ownerPhone: '',
                price: '',
                remark: '',
                subbranch: '',
            },
            account: 0
        })

        onMounted(() => {
            _remaining()
        })

        const _remaining = async () => {
            const res:any  = await remaining()
            state.account = res.data
            localStorage.setItem('M', res.data)
        }

        const onBack = () => {
            router.push({path: '/amount'})
        }

        const validatorPrice = (value: any) => {
            if (!value) throw  '退款金额不能为空！'
            const reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
            if (!reg.test(value))throw '金额格式有误!'
            if (Number(value) > Number(state.account))throw '不可大于可退余额!'
            return true
        }

        const validatorBank = (value: any) => {
            if (!value) throw '开户银行不能为空!'
            if (value.length > 100) throw '开户银行不能超过100字符!'
            return true
        }

        const validatorBankAccount = (value: any) => {
            if (!value) throw'银行账号不能为空!'
            const re = /^[0-9]+.?[0-9]*/;
            if (!re.test(value))throw'银行账号格式错误!'
            return true
        }
    
        const validatorOwnerName = (value: any) => {
            if (!value) throw'户名不能为空!'
            if (value.length > 100) throw'户名不能超过100字符!'
            return true
        }
    
        const validatorPhone = (value: any) => {
            if (!value) throw'手机号不能为空!'
            const myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(value))throw'手机号格式有误!'
            return true
        }
    
       
        const onCheck = (state: any) => {
            try {
                validatorPrice(state.price)
                validatorBank(state.bank)
                validatorBankAccount(state.bankAccount)
                validatorOwnerName(state.ownerName)
                validatorPhone(state.ownerPhone)
                return true
            } catch (error) {
                proxy.$toast(error)
                console.log(error, '看看打印了什么...')
               
            }
        }

        const _refundCreate =  async (data: any) => {
            const res:any = await refundCreate(data)
            localStorage.setItem(SET_REFUND_RESULT, JSON.stringify({ info: state.form }))
            router.push({path: '/refundResult'})
        }

        const onFinish = () => {
           const bool =  onCheck(state.form)
           if(!bool)return 
           console.log(state.form)
           _refundCreate(state.form)
        }

        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>申请退款</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class="refund-box">
                    <div class="headers-box">
                        <img class="icon-box" src="https://img.fanwoon.com/redtishi%402x.png" />
                        <span>退款说明</span>
                    </div>
                    <div class="contents-box">
                        <div class="item-box">
                            1. 退款将以转账方式汇入您填写的银行信息；
                        </div>
                        <div class="item-box">
                            2. 请务必保证下列信息准确，如果退款失败，不会影响账户的余额；
                        </div>
                        <div class="item-box">
                            3. 提交退款后，预计<span class="danger-box">30个工作日</span>内完成退款;
                        </div>
                        <div class="item-box">
                            4.由于不同银行的政策不同，请以银行实际到账时间为准。
                        </div>
                    </div>
                    <div class="refund-form-box">
                        <div>
                            <div class="label-bar">退款金额</div>
                            <div><input v-model={state.form.price} class="input-box" placeholder="请输入退款金额" /></div>
                            <div class="refund-tips">当前账户可退款金额：<span class="money">{Number(!state.account ? 0 : state.account).toFixed(2)}</span></div>
                        </div>
                        <div>
                            <div class="label-bar">退款至以下银行</div>
                            <div>
                                <div class="label-item-box"><span>*</span>开户银行</div>
                                <input v-model={state.form.bank} class="input-box" placeholder="请输入开户行" />
                            </div>
                            <div>
                                <div class="label-item-box"><span>*</span>支行名称</div>
                                <input v-model={state.form.subbranch} class="input-box" placeholder="请输入支行名称" />
                            </div>
                            <div>
                                <div class="label-item-box"><span>*</span>银行账号</div>
                                <input v-model={state.form.bankAccount} class="input-box" placeholder="请输入银行账号" />
                            </div>
                            <div>
                                <div class="label-item-box"><span>*</span>开户人姓名</div>
                                <input v-model={state.form.ownerName} class="input-box" placeholder="请输入开户人姓名" />
                            </div>
                            <div>
                                <div class="label-item-box"><span>*</span>手机号码</div>
                                <input v-model={state.form.ownerPhone} class="input-box" placeholder="请输入手机号码" />
                            </div>
                            <div>
                                <div class="label-item-box">留言</div>
                                <textarea v-model={state.form.remark} rows="4" class="input-box texare-box" placeholder="请输入留言" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="refund-footer-box">
                    <button class="cancel-btn" onClick={onBack}>取消</button>
                    <button class="sub-btn" onClick={onFinish}>提交退款申请</button>
                </div>
            </div>
        )
    }
})